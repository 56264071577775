import { Link } from "@remix-run/react";
import { Icons } from "~/components/Icons";
import { Flex, Heading, Button } from "@radix-ui/themes";
import logo from "~/images/vendr-backoffice-logo.webp";

export default function AuthenticationPage() {
  return (
    <Flex flexGrow="1" direction="column" align="center" justify="center">
      <img height="400px" src={logo} alt="Vendr Backoffice Logo" />
      <Heading
        style={{ fontSize: "6em", lineHeight: "1em", marginBottom: "0.5em" }}
      >
        Backoffice
      </Heading>
      <Button variant="soft" size="4" asChild>
        <Link to="/auth">
          Login With
          <Icons.oktaWords height="1.3em" />
        </Link>
      </Button>
    </Flex>
  );
}
